import {FileStorage} from "../../../api/sdl";
import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {PlayModal} from "./PlayModal";
import {getFilesUrl} from "../../../shared";
import {LoadingButton} from "@mui/lab";
import {Play, Record} from "phosphor-react";

export const Question = (props: {title: string, onRecordClick: () => void, url?: string, fileStorage?: FileStorage | null, disabled: boolean, loading: boolean}) => {
  const [open, setOpen] = React.useState(false);

  return <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-between', gap: 3}}>
    {props.url && <PlayModal url={getFilesUrl(props.fileStorage) + '/' + props.url} open={open} onClose={() => {
      setOpen(false);
    }}/>}
    <Typography>{props.title}</Typography>
    <Box sx={{display: 'flex', gap: 3}}>
      <div>
        <LoadingButton variant={'outlined'} color={'error'} loading={props.loading} disabled={props.disabled}
                       startIcon={<Record size={16} color="#D3232F" weight="fill" />}
                       onClick={props.onRecordClick}>Record</LoadingButton>
      </div>
      <Box sx={{minWidth: 87}}>
        {!!props.url && <Button variant={'outlined'} color={'success'} disabled={props.disabled}
                                startIcon={<Play size={16} color="#1A7D36" weight="fill" />}
                                onClick={() => {
                                  setOpen(true);
                                }}>Play</Button>}
      </Box>
    </Box>
  </Box>
}

