import React, {useContext} from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch, BrowserRouter,
} from 'react-router-dom';
import RegisterCompany from "./pages/RegisterCompany";
import {Box, CircularProgress} from "@mui/material";
import {withProfileContextProvider} from "../shared/ProfileContext";
import ThankYou from "./pages/ThankYou";
import PrivateRoute from "../shared/PrivateRoute";
import Positions from "./pages/Positions";
import Layout from "../shared/components/Layout";
import {keycloak, KeycloakContext} from "../keycloak";
import {client} from "../ApolloClient";
import {gql} from "graphql-tag";
import CompanyContracts from "./pages/CompanyContracts";
import HiringComplete from "./pages/HiringComplete";
import CompanyMeetings from "./pages/company-meetings";
import {MixpanelTracker} from "../feature/mixpanel";
import PositionPage from "./pages/position-page";
import Notifications from "../feature/notifications";
import {CandidatesPage} from "../domain/matching_process";
import {HirePage} from "../domain/hiring";
import {ReschedulePage} from "../domain/matching_process";


function CompanyApp(props: RouteComponentProps<any>) {
  const {initialized, keycloak} = useContext(KeycloakContext);

  if (!initialized && keycloak?.authenticated) {
    return <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
      <CircularProgress size="15" />
    </Box>
  }

  return (
      <BrowserRouter basename={'/company'}>
        <MixpanelTracker />
          <Switch>
            <Route exact path={'/'} component={RegisterCompany} />
            <Layout menu={menu}>

              <PrivateRoute exact path={'/contracts'} component={CompanyContracts} />
              <PrivateRoute exact path={'/hire/:positionId/:positionMatchId'} component={HirePage} />
              <PrivateRoute exact path={'/interviews/:positionId?'} component={CompanyMeetings} />
              <PrivateRoute exact path={'/reschedule/:positionMatchId/meeting/:meetingId'} component={ReschedulePage} />
              <PrivateRoute exact path={'/positions'} component={Positions} />
              <PrivateRoute exact path={'/position/:id?'} component={PositionPage} />
              {/*<PrivateRoute exact path={'/candidates/:positionId/candidate/:positionMatchId/schedule'} component={(props: any) => <Candidates {...props} schedule/>} />*/}
              {/*<PrivateRoute exact path={'/candidates/:positionId/candidate/:positionMatchId'} component={Candidates} />*/}
              {/*<PrivateRoute exact path={'/candidates/:positionId'} component={Candidates} />*/}

              <PrivateRoute exact path={'/candidates/:positionId/:positionMatchId/schedule'} component={(props: any) => <CandidatesPage {...props} schedule/>} />
              <PrivateRoute exact path={'/candidates/:positionId/:positionMatchId?'} component={CandidatesPage} />

              <PrivateRoute exact path={'/thank-you'} component={ThankYou} />
              <PrivateRoute exact path={'/notifications'} component={Notifications} />
              <PrivateRoute exact path={'/hiring-complete'} component={HiringComplete} />
            </Layout>
            <Route>
              <Redirect to={'/'} />
            </Route>
          </Switch>
      </BrowserRouter>
  );
}

export default withProfileContextProvider(CompanyApp);

const menu = async () => {
  const notifications = client.query({
    query: gql`{myNotificationCounter}`,
    fetchPolicy: 'no-cache'
  })
  return {
    top: [
      {
        title: 'Roles',
        route: '/positions',
        icon: 'briefcase',
      },
      {
        title: 'Interviews',
        icon: 'appointments',
        route: '/interviews',
      },
      {
        title: 'Notifications',
        icon: 'bell',
        route: '/notifications',
        notifications: await notifications.then(r => r.data.myNotificationCounter),
      },
      {
        title: 'Contracts',
        icon: 'appointments',
        route: '/contracts',
      },
    ],
    bottom: [
      {
        title: 'Logout',
        icon: 'user-circle',
        onClick: () => keycloak.logout({redirectUri: document.location.origin})
      }
    ]
  }
}
