import {RouteComponentProps, useHistory} from "react-router-dom";
import {
  Box, Button, Container, Typography
} from "@mui/material";
import React from "react";
import {withLayout} from "../../shared/components/Layout";
import menu from "../components/menu";
import IntroductionVideoForm from "../../feature/introduction-video-form/IntroductionVideoForm";
import IntroductionVideoView from "../../feature/introduction-video-view/IntroductionVideoView";
import * as Toggle from "@radix-ui/react-toggle";
import {InterviewAnswer, useMyAnswersLazyQuery} from "../../api/sdl";
import ContractorCardBottomNav from "../components/ContractorCardBottomNav";
import {useIsMobile} from "../../shared";

const VideoPage = (props: RouteComponentProps<any>) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const [preview, setPreview] = React.useState(false);
  const [answers, setAnswers] = React.useState<Partial<InterviewAnswer>[]>([])
  const [getAnswers, {loading}] = useMyAnswersLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: response => setAnswers(response.myAnswers)
  });

  React.useEffect(() => {
    if (preview) {
      getAnswers();
    } else {
      setAnswers([]);
    }
  }, [preview])

  return <Container sx={{p: 3}} maxWidth={'lg'}>
    <Typography variant={'h2'} sx={{mb: 3}}>My sales interview video</Typography>
    <Typography sx={{mb: 1}}>
      This is a one way video assessment of your sales skills. We recommend you do not record on mobile and use Google Chrome for the best quality.
    </Typography>
    <ol>
      <li>Click the <b>Record</b> button.</li>
      <li>Press the <b>Stop</b> button, and it will automatically upload the recording.</li>
      <li>Hit <b>Record</b> again to re-record if you need to redo your answer.</li>
    </ol>
    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 5}}>
      <Button
        component={Toggle.Root}
        variant="outlined"
        color="secondary"
        pressed={preview}
        onPressedChange={setPreview}
        sx={(theme) => ({
          boxShadow: 0,
          /*'&[data-state="on"]': {
            boxShadow: theme.shadows.md.replace(/,/gm, ", inset "),
            ...theme.variants.soft.secondary
          }*/
        })}
      >
        Preview
      </Button>
    </Box>
    {!preview && <IntroductionVideoForm onSubmit={() => history.push('/profile')} />}
    {preview && <IntroductionVideoView loading={loading} answers={answers} />}
    {isMobile && <ContractorCardBottomNav index={2} onChange={() => history.goBack()} />}
  </Container>;
}

export default withLayout(VideoPage, menu)
