import {
  Box,
  Button, CircularProgress
} from "@mui/material";
import React from "react";
import {
  useMyAnswersQuery,
  useVideoQuestionsQuery,
} from "../../api/sdl";
import {useSnackbar} from "notistack";
import {ProfileContext} from "../../shared/ProfileContext";
import VideoRecordModal from "../video-record-modal/VideoRecordModal";
import {Question} from "./ui/Question";

export default function IntroductionVideoForm({onSubmit}: {onSubmit: () => void}) {
  const videoQuestions = useVideoQuestionsQuery();
  const [profile] = React.useContext(ProfileContext);
  const myAnswers = useMyAnswersQuery({fetchPolicy: 'no-cache'});
  const [index, setIndex] = React.useState<number>();
  const [recordOpen, setRecordOpen] = React.useState(false);
  const [recordOpening, setRecordOpening] = React.useState<number>();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);


  const savedAnswers = React.useMemo(() => {
    return myAnswers?.data?.myAnswers || [];
  }, [myAnswers.data])

  const onRecordClick = async (index: number) => {
    try {
      setIndex(index);
      setRecordOpening(index);
      setRecordOpen(true);
    } catch (error: any) {
      enqueueSnackbar(error.message)
      setRecordOpening(undefined);
      return
    }
    setRecordOpening(undefined);
  }

  const questions = React.useMemo(() => {
    return videoQuestions.data?.videoQuestions || [];
  }, [videoQuestions.data?.videoQuestions])

  const onRecorded = () => {
    myAnswers.refetch()
  }

  React.useEffect(() => {
    setLoading(myAnswers.loading || videoQuestions.loading)
  }, [myAnswers.loading, videoQuestions.loading])

  return <Box sx={{position: 'relative', mt: 5}}>
    <VideoRecordModal open={recordOpen}
                 title={questions && index !== undefined ? questions[index!]?.title : ''}
                 onRecorded={onRecorded}
                 contractorId={profile?.contractorProfile?.contractorId}
                 videoQuestionId={questions[index || 0]?.id}
                 onClose={() => {
                   setIndex(undefined);
                   setRecordOpen(false);
                 }} />
    {loading && <Box sx={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: '#ffffffa3', zIndex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress size="15" />
    </Box>}
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, flex: 1}}>
      {questions.map((q, i) => <Question key={i}
                                         url={savedAnswers.find(a => a.videoQuestion.id === q.id)?.url}
                                         loading={recordOpening === i}
                                         fileStorage={savedAnswers.find(a => a.videoQuestion.id === q.id)?.fileStorage}
                                         disabled={typeof recordOpening !== 'undefined'}
                                         onRecordClick={() => {
                                           onRecordClick(i)
                                         }}
                                         title={q.title}  />)}
      <Box sx={{mt: 3, display: 'flex', gap: 3}}>
        <Button variant={'outlined'} color={'secondary'} onClick={onSubmit}>Back to profile</Button>
      </Box>
    </Box>
  </Box>;
}
