import React from "react";
import {Box, Dialog, IconButton} from "@mui/material";
import {X} from "phosphor-react";

export function PlayModal(props: {url: string, open: boolean, onClose: () => void}) {
  React.useEffect(() => {
    if (props.open && props.url) {
      setTimeout(() => {
        let video = document.querySelector("#video-play");
        // const objectUrl = URL.createObjectURL( props.url );
        (video as any).src = props.url;
        (video as any).load();
      }, 300)
    } else if(props.url) {
      setTimeout(() => {
        /*URL.revokeObjectURL( url );
        setUrl(undefined)*/
      }, 300)
    }
  }, [props.open, props.url]);

  const clearVideo = () => {
    let video = document.querySelector("#video-play");
    if (video) {
      (video as any).src = '';
    }
  }

  return <Dialog open={props.open}
                 maxWidth={'xl'}
                 fullWidth={true}

                 sx={{p: 0}}
                 onClose={() => {
                   clearVideo();
                   props.onClose()
                 }}>
    <Box component={'video'} controls sx={{width: {md: 1}, borderRadius: '8px'}} id="video-play" autoPlay={true}
         style={{display: 'block'}}></Box>
    <IconButton size={'small'} onClick={() => {
      clearVideo();
      props.onClose()
    }} sx={{
      position: 'absolute',
      top: 16,
      right: 16,
      zIndex: 2,
      '&:hover': {background: 'transparent'},
    }}>
      <X size={30} color="#fff" weight="fill"/>
    </IconButton>
  </Dialog>
}
