import {RouteComponentProps} from "react-router";
import React, {useMemo} from "react";
import {ProfileContext} from "../../../shared/ProfileContext";
import {withLayout} from "../../../shared/components/Layout";
import menu from "../../../contractor/components/menu";
import ContractorPositionWidget from "../feature/contractor-position/ContractorPositionWidget";
import {PositionMatchContextProvider} from "../context/PositionMatchContext";


const ContractorPositionDetailsPageInternal = (props: RouteComponentProps<{positionId: string}>) => {

  const { positionId } = props.match.params;
  const [profile] = React.useContext(ProfileContext);

  const contractorId = useMemo(() => {
    return profile?.contractorProfile?.contractorId
  }, [profile]);

  return <PositionMatchContextProvider positionId={positionId} contractorId={contractorId} >
    <ContractorPositionWidget/>
  </PositionMatchContextProvider>
}

export const ContractorPositionDetailsPage =  withLayout(ContractorPositionDetailsPageInternal, menu);
