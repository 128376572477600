import {useHistory} from "react-router-dom";
import {Box, Button, Container, Divider, Typography} from "@mui/material";
import React, {useContext} from "react";
import {SignIn, SignOut} from 'phosphor-react';
import {ContractorStatus} from "../../../api/sdl";
import {ProfileContext} from "../../../shared/ProfileContext";
import CreateContractorForm from "../../../shared/components/CreateContractorForm";
import {KeycloakContext} from "../../../keycloak";
import {useIsMobile} from "../../../shared";

export default function RegisterContractor() {
  const history = useHistory();
  const {keycloak} = useContext(KeycloakContext);
  const [profile] = React.useContext(ProfileContext);
  const isMobile = useIsMobile();

  React.useEffect(() => {
    if (keycloak?.authenticated) {
      if (profile) {
        history.push( [
          ContractorStatus.Approved,
          ContractorStatus.FillingAssessment,
          ContractorStatus.PreApprovedInterview,
          ContractorStatus.FillingVideo].includes(profile?.contractorProfile?.status!)  ? '/notifications' : '/personal-info')
      }
    }
  }, [keycloak?.authenticated, profile])


  return <Container maxWidth={'lg'} sx={{mb: 10}}>
    <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, width: 1, mt: {xs: 3, md: 0}, height: {md: 80}, mb: 3, alignItems: 'center', justifyContent: 'space-between'}}>
      <Box component={'img'} src={'/img/MDliaison_logo.png'} sx={{cursor: 'pointer'}} onClick={() => document.location.href = 'https://www.mdliaison.com/'} width={200} height={44} />
      <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
        {!keycloak?.authenticated && <>
          <Button variant={'outlined'} data-test='company-signup' color={'primary'}
                  onClick={() => document.location.href = document.location.origin + '/company'}
          >Sign up as company</Button>
          <Button variant={'outlined'} color={'primary'}
                  data-test="login"
                  onClick={() => document.location.href = keycloak.createLoginUrl({redirectUri: document.location.href})}
                  startIcon={<SignIn size={24} color="#37c9ef" weight="regular" />}>Login</Button>
        </>}
        {!!keycloak?.authenticated && <Button variant={'outlined'} color={'primary'}
                                              onClick={() => {
                                                keycloak.logout()
                                              }}
                                              startIcon={<SignOut size={24} color="#37c9ef" weight="regular" />}>Logout</Button>}
      </Box>
    </Box>
    <Box>
      <Typography variant={isMobile ? 'h3' : 'h1'} sx={{textAlign: 'center', mb: 3}}>Apply to join<br />the top healthcare sales rep<br/>network</Typography>
      <Typography sx={{textAlign: 'center', mb: 10, color: '#2c92d5'}}>MDliaison is an exclusive network of top sales talent from the tech, medical device, pharma, physician practice, and hospital industries.We provide access to top companies, a community of experts, and resources that can help accelerate your career.</Typography>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 5, justifyContent: 'center'}}>
        <CreateContractorForm onCompleted={data => {
          sessionStorage.setItem('token', JSON.stringify(data.token));
          document.location.href = '/contractor/personal-info'
        }}/>
        <Divider orientation={'vertical'} />
        <Box>
          <Typography variant={'body1'} sx={{textAlign: 'center'}}><b>Work with top companies</b></Typography>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 3}}>
            <Box sx={{display: 'flex',  flexDirection: 'column', gap: 3, alignItems: 'center', }}>
              <Box component={'img'} src={'/img/logo1.png'} width={200} height={63} />
              <Box component={'img'} src={'/img/logo2.png'} width={200} height={32} />
              <Box component={'img'} src={'/img/logo3.png'} width={200} height={52} />
              <Box component={'img'} src={'/img/logo5.jpeg'} width={100} />
              <Box component={'img'} src={'/img/logo6.png'} width={200} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Container>;
}
