import React, {useContext} from "react";
import {
  Route,
  RouteComponentProps,
  Switch, BrowserRouter,
} from 'react-router-dom';
import RegisterContractor from "./pages/onboarding/RegisterContractor";
import {Box, CircularProgress} from "@mui/material";
import {ProfileContext, withProfileContextProvider} from "../shared/ProfileContext";
import PersonalInfo from "./pages/onboarding/PersonalInfo";
import ProfessionalProfile from "./pages/ProfessionalProfile";
import PrivateRoute from "../shared/PrivateRoute";
import VideoPage from "./pages/VideoPage";
import menu from "./components/menu";
import {withLayout} from "../shared/components/Layout";
import ContractorPositions from "./pages/ContractorPositions";
import CalendarPage from "./pages/CalendarPage";
import Settings from "../company/pages/Settings";
import {KeycloakContext} from "../keycloak";
import {MixpanelTracker} from "../feature/mixpanel";
import OnboardingCompleted from "./pages/onboarding/OnboardingCompleted";
import Notifications from "../feature/notifications";
import {BalanceReportsPage} from "../feature/balance-reports";
import {ContractorPayouts} from "./pages/contractor-payouts/ContractorPayouts";
import {ContractorPositionDetailsPage} from "../domain/matching_process";


function ContractorApp(props: RouteComponentProps<any>) {
  const {initialized, keycloak} = useContext(KeycloakContext);
  const [profile] = React.useContext(ProfileContext);

  if (initialized && keycloak?.authenticated && !profile) {
    return <Box sx={{width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
      <CircularProgress sx={{width: 25}} size={25} />
    </Box>
  }

  return (
    <BrowserRouter basename={'/contractor'}>
      <MixpanelTracker />
      <Switch>

        <PrivateRoute exact path={'/personal-info'} component={PersonalInfo} />
        <PrivateRoute exact path={'/onboarding-completed'} component={OnboardingCompleted} />
        <PrivateRoute exact path={'/notifications'} component={withLayout(Notifications, menu)} />
        <PrivateRoute exact path={'/payouts'} component={withLayout(ContractorPayouts, menu)} />
        <PrivateRoute exact path={'/positions/:positionId'} component={ContractorPositionDetailsPage} />
        <PrivateRoute exact path={'/position/:positionId'} component={ContractorPositionDetailsPage} />
        <PrivateRoute exact path={'/positions'} component={ContractorPositions} />
        <PrivateRoute exact path={'/profile'} component={ProfessionalProfile} />
        <PrivateRoute exact path={'/settings'} component={withLayout(Settings, menu)} />
        <PrivateRoute exact path={'/calendar'} component={withLayout((props: any) => <CalendarPage {...props} contractorId={profile?.contractorProfile?.contractorId} />, menu)} />
        <PrivateRoute exact path={'/schedule'} component={withLayout(CalendarPage, menu)} />
        <PrivateRoute exact path={'/video'} component={VideoPage} />
        <PrivateRoute exact path={'/balance-reports'} component={withLayout(BalanceReportsPage, menu)} />

        <Route exact path={'/'} component={RegisterContractor} />

      </Switch>
    </BrowserRouter>
  );
}

export default withProfileContextProvider(ContractorApp);


