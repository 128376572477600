import {Box, CircularProgress, Button} from "@mui/material";
import React, {useState} from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {
  useContractorProfileByIdQuery
} from "../../../api/sdl";
import {useSnackbar} from "notistack";
import ContractorApprovement from "../../components/ContractorApprovement";
import {ContractorCard} from "../../../widget";
import {Candidate, ContractorProfile} from "../../../company/pages/Candidates/lib/types";
import {EducationApiData} from "../../../widget/contractor-card/ui/education-form/types";


export default function ContractorDetails(props: RouteComponentProps<{ id: string }>) {
  const history = useHistory();
  const [profile, setProfile] = useState<ContractorProfile>()
  const [educations, setEducations] = useState<EducationApiData[]>()
  const {data, loading, refetch} = useContractorProfileByIdQuery({
    variables: {id: props.match.params.id},
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setProfile(data?.contractorProfileById)
      setEducations(data?.educations || [])
    }
  });
  const {enqueueSnackbar} = useSnackbar();

  return <Box sx={{position: 'relative', minHeight: '50vh'}}>
    {loading && <Box sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
        <CircularProgress size="15"/>
    </Box>}
    {!!profile && <>
        <Button variant="text" sx={{mb: 3, textDecoration: 'underline'}} color="inherit"
                onClick={() => history.push('/contractors')}>Back</Button>
        <ContractorCard profile={profile.contractorProfile || undefined} userProfile={profile.profile || undefined}
                        onUpdate={() => {
                          enqueueSnackbar('Profile was saved successfully', {variant: 'success'})
                          refetch()
                        }}
                        extraContent={profile.contractorProfile ?
                          <ContractorApprovement profile={profile.contractorProfile!!} onChange={refetch}/> : undefined}
                        editable
                        educations={educations}
                        showSideStripe={true}
        />
    </>}
  </Box>
}
